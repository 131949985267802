<template>
  <div class="app-container">
    <el-card>
      <div class="info">
        <span>评分类别：</span>
        <el-select class="b1" @change="changeSelect(infoForm.sctid)" v-model="infoForm.scsid" placeholder="请选择">
          <el-option v-for="item in roleList" :key="item.id" :label="item.category" :value="item.id" />
        </el-select>
        <!-- <span>评分明细：</span>
        <el-select
          class="b1"
          v-model="infoForm.sctid"
          placeholder="请先选类别"
          
        >
          <el-option
            v-for="item in detailList"
            :key="item.sctid"
            :label="item.content"
            :value="item.sctid"
          />
        </el-select> -->
        <!-- v-if="idis == '校负责领导' ? true : false" -->
        <el-button style="height: 40px;" type="primary" @click="searchInfo()">查询</el-button>
        <!-- v-if="idis == '校负责领导' ? true : false" -->
        <el-button style="height: 40px;" type="primary" plain class="ml10" @click="addInfo()">新增</el-button>
        <!-- 模板下载 -->
        <el-button style="height: 40px;" type="primary" plain class="ml10" @click="downloadTemplate()">模板下载</el-button>
        <!-- 导入指标项 -->
        <el-upload class="upload-data" :action="imgBaseUrl + '/api/import/importMetricsData?rowType=0'"
          accept=".xlsx,.xls" :on-success="onSuccess" :on-error="uploadError" contenteditable="" :show-file-list="false">
          <el-button type="primary" plain class="ml10">导入</el-button>
        </el-upload>
      </div>
      <el-table class="mgt16" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
        element-loading-text="Loading" border fit highlight-current-row>
        <el-table-column label="序号" align="center" type="index" width="60px"></el-table-column>
        <el-table-column align="left" label="评分类别">
          <template slot-scope="scope">{{ scope.row.category }}</template>
        </el-table-column>
        <el-table-column align="left" label="评分明细">
          <template slot-scope="scope">{{ scope.row.content }}</template>
        </el-table-column>
        <el-table-column align="left" label="计分上限">
          <template slot-scope="scope">{{ scope.row.maxscore }}</template>
        </el-table-column>
        <el-table-column align="left" label="计分下限">
          <template slot-scope="scope">{{ scope.row.minscore }}</template>
        </el-table-column>
        <el-table-column align="left" label="起始分">
          <template slot-scope="scope">{{ scope.row.score }}</template>
        </el-table-column>
        <el-table-column align="left" label="计分步长">
          <template slot-scope="scope">{{ scope.row.scorestep }}</template>
        </el-table-column>
        <el-table-column property="address" label="操作" align="left" width="150px">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-button type="primary" size="small" @click="handleView(scope.row)">编辑</el-button>
              <el-button type="danger" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog v-if="dialogFormVisible" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible"
        :before-close="handleClose" width="30%" minWidth="600px">
        <stuindexsetting ref="dialogForm" @changeDialogFormVisible="changeDialogFormVisible($event)"
          :dialog-status="dialogStatus" :dialogStatus="dialogStatus" />
      </el-dialog>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import * as api from "@/api/users";
import { getHttpUrl } from "@/utils/request";
import stuindexsetting from "@/views/add/stuindexsetting.vue";
export default {
  components: {
    stuindexsetting,
  },
  data() {
    return {

      imgBaseUrl: getHttpUrl(),

      page: 1,
      pageSize: 10,
      form: {},
      infoForm: {},
      idis: "",
      formLabelWidth: "120px",
      Authorization: "",
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "编辑",
        create: "新增",
        view: "详情",
      },
      currentPage4: 4,
      listQuest: {
        page: 1,
        pageSize: 10,
      },
      roleList: [],
      detailList: [],
      total: 0,
      options: [
        {
          label: "有",
          value: 1,
        },
        {
          label: "没有",
          value: 0,
        },
      ],
      roles: [],
      tableData: [],
      input: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // detailList1(){
    //   let a=this.receiveDetailList()
    //   console.log(a);
    // },
    addInfo() {
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
    },
    // receiveDetailList(val) {
    //   console.log(val, "009");
    //   console.log(this.roleList[val - 1].category, this.roleList[val - 1]);
    //   let a = { page: this.page, pageSize: this.pageSize, starts: 0 };
    //   a.sctid = val;
    //   api.stuScoringDetails(a).then((res) => {
    //     // this.detailList;
    //     console.log(res,"detailList");

    //     this.detailList = res.data;
    //   });
    // },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.d1();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.d1();
    },
    // 查询
    searchInfo() {
      // 读取 指标 列表 (评分明细列表)
      api.getScoreContentList({
        page: this.page,
        pageSize: this.pageSize,
        scsid: this.infoForm.scsid,
        sctid: this.infoForm.sctid,
        starts: 0,
      })
        .then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        });
    },
    handleDelete(value) {
      api.deleteStuScoringDetails(value).then((res) => {
        console.log(res);
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.d1();
      });
    },
    handleView(row) {
      console.log(row, "111");
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.form = row;
      this.$nextTick(() => {
        this.$refs.dialogForm.showData(this.form);
      });
    },
    indexMethod(index) {
      return index * 1;
    },
    d1() {
      // 读取 指标 列表 (评分明细列表)
      api.getScoreContentList({
        page: this.page,
        pageSize: this.pageSize,
        starts: 0,
      })
        .then((res) => {
          if (res.status == 1) {
            this.tableData = res.data.rows;
            this.total = res.data.total;
            for (let i = 0; i < this.tableData.length; i++) {
              this.tableData[i].index = i + 1;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 获取学生指标评分明细选框
    changeSelect() {
      let params = {
        scsid: this.infoForm.scsid,
        starts: 0,
      };
      // 读取 指标 列表 (评分明细列表)
      api.getScoreContentList(params).then((res) => {
        this.total = res.data.total;
        this.detailList = res.data.rows;
      });
    },
    // 获取学生指标评分类别选框
    selection() {
      api.getScoreCategoryList({
        page: this.page,
        pageSize: 1000,
        starts: 0,
      })
        .then((res) => {
          this.roleList = res.data.records;
          console.log(res);
        });
      this.changeSelect();
    },
    changeDialogFormVisible(data, ifMsg) {
      this.dialogFormVisible = data;
      this.d1();
      if (ifMsg) {
        this.$message({
          message: this.dialogStatus == "update" ? "编辑成功" : "添加成功",
          type: "success",
        });
      }
    },
    // select(e) {
    //   console.log(e);
    //   //   if (e.target.value) {
    //   //     // 不在表单的时候不需要判断，也不需要isname字段
    //   //     this.ruleForm.subjectId = e.target.value;
    //   //     this.isName = true;
    //   //   } else {
    //   //     this.isName = false;
    //   //   }
    // },
    getType() {
      api.getCurrUserItem().then((res) => {
        this.idis = res.data.rname;
      });
    },
    loadData() {
      this.d1();
      this.selection();
      this.getType();
    },
    handleClose(done) {
      done();
    },
    handleEdit(row) {
      this.getRole();
      console.log(row);
      this.form.id = row.id;
      this.roles.roleName = row.role_name;
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    goSearch(form) {
      console.log(form, "赶紧的");
      form.page = 1;
      form.pageSize = 1000;
      if (!form.classnum) {
        form.classnum = "";
      }
      if (!form.roleid) {
        form.roleid = "";
      }
    },

    // 上传成功勾子
    onSuccess(response) {
      let me = this;
      if (response.status === -1) {
        // me.$message.error(response.msg);
        me.$confirm(response.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error'
        }).then(() => { }).catch(() => { });
      } else {
        me.$message({ message: '数据导入成功', type: 'success' });
        me.loadData();
      }
    },

    /**
     * 文件上传失败回调
     */
    uploadError(err) {
      let me = this;
      console.log("err====== ", err);
      // me.$message.error("数据导入失败！");
      me.$confirm('数据导入失败！', '提示', {
        confirmButtonText: '确定',
        type: 'error'
      }).then(() => { }).catch(() => { });
    },

    /**
     * 模板下载
     */
    downloadTemplate() {
      window.location.href = this.imgBaseUrl + "/api/import/exportTemplate?rowType=4"
    }

  },
};
</script>
<style lang="scss" scoped>
.wrap {
  span {
    margin-right: 15px;
  }
}

/deep/ .el-container {
  width: 100%;
  height: 100% !important;
}

.warp {
  text-align: left;
  margin-left: 20px;
}

.gutter {
  width: 200px;
}

.b1 {
  margin-left: 15px;
}

.b2 {
  margin: 0px 15px;
}

/deep/.el-dialog__body {
  padding: 0px;
}

/deep/.el-dialog__title {
  display: flex;
  flex-direction: row;
}

.upload-data {
  float: left;
}
</style>
