<template>
  <div>
    <el-row :gutter="1">
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }">
        <!-- :lg="{ span: 10 }"
        :xl="{ span: 24 }" -->
        <hr style="border: 0.5px solid blue; margin-bottom: 20px" />
        <el-form ref="form" :model="form" :inline="true" :rules="rules" label-position="center" label-width="100px">
          <el-form-item label="评分类别:" prop="scsid">
            <el-select class="item2" v-model="form.scsid" filterable allow-create default-first-option
              placeholder="请选择评分类别">
              <el-option v-for="item in options" :key="item.id" :label="item.category" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="评分明细:" prop="content" class="label1">
            <el-input v-model="form.content" placeholder="请填写评分明细"></el-input>
            <!-- <el-select
              class="b1"
              v-model="form.content"
              placeholder="请先选类别"
              allow-create
              filterable
            >
              <el-option
                v-for="item in detailList"
                :key="item.content"
                :label="item.content"
                :value="item.content"
              />
            </el-select> -->
          </el-form-item>
          <!--学生指标修改-->
          <el-form-item label="计分上限:" prop="maxscore">
            <el-input-number class="item1" v-model="form.maxscore" controls-position="right" :min="-100" :max="100"
              placeholder="-20 到 20"></el-input-number>
          </el-form-item>
          <el-form-item label="计分下限:" prop="minscore">
            <el-input-number class="item1" v-model="form.minscore" controls-position="right" :min="-100" :max="100"
              placeholder="-20 到 20"></el-input-number>
          </el-form-item>
          <el-form-item label="初始分:" prop="score">
            <el-input-number class="item1" v-model="form.score" controls-position="right" :min="-100" :max="100"
              placeholder="-20 到 20"></el-input-number>
          </el-form-item>
          <el-form-item label="初始步长:" prop="scorestep">
            <el-input-number class="item1" v-model="form.scorestep" controls-position="right" :min="1" :max="10"
              placeholder="1 到 10"></el-input-number>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" style="text-align: center; margin-bottom: 20px">
          <el-button @click="cancel()"> 取消 </el-button>
          <el-button type="primary" @click="
            dialogStatus == 'create' ? createData('form') : updateData('form')
            ">
            确认
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { getMechanism } from "@/api/resource/index";
// import * as API from "@/api/user";
import * as api from "@/api/users";
export default {
  components: {
    //formMap
  },
  props: ["dialogStatus"],
  data() {
    return {
      tableData: [], // 评分明细列表
      starttime: "",
      endtime: "",
      options: [],
      detailList: [],
      value: [],
      returnImgUrl: "",
      readonly: true,
      rules: {
        scsid: [{ required: true, trigger: "blur", message: '评分类别不能为空！' }],
        content: [{ required: true, trigger: "blur", message: '评分明细不能为空！' }],
        maxscore: [{ required: true, trigger: "blur", message: '记分上限不能为空！' }],
        minscore: [{ required: true, trigger: "blur", message: '记分下限不能为空！' }],
        score: [{ required: true, trigger: "blur", message: '初始分不能为空！' }],
        scorestep: [{ required: true, trigger: "blur", message: '初始步长不能为空！' }],
      },
      typeList: [],
      form: {},
      roleList: [],
      userTypeList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    console.log(this.dialogStatus, "aoligei");
    this.load();
  },
  mounted() { },
  methods: {
    // receiveDetailList(val) {
    //   console.log(val, "009");
    //   console.log(this.options[val - 1].category, this.options[val - 1]);
    //   let a = { page: this.page, pageSize: this.pageSize, starts: 0 };
    //   a.content = val;
    //   api.stuScoringDetails(a).then(res => {
    //     // this.detailList;
    //     console.log(res);
    //     this.detailList=res.data
    //     console.log(res.data,"取到数据了吗")
    //   });
    // },
    // 获取学生指标评分明细选框
    changeSelect() {
      let params = {
        scsid: this.form.scsid,
        starts: 0,
      };
      // 读取 指标 列表 (评分明细列表)
      api.getScoreContentList(params).then((res) => {
        console.log(res, "所有评分明细");
        this.detailList = res.data.rows;
        console.log(this.detailList, "评分明细选框数据");
      });
    },
    t1() { },
    beforeAvatarUpload(file) {
      console.log(file, "klllkl");
    },
    dnameChanged(value) {
      console.log(this.systemList[value - 1].name, "aaaaaaaaa");
      api
        .getCnameList({
          page: 1,
          pageSize: 10,
        })
        .then((res) => {
          console.log(res.data.records);
          this.cnameList = res.data.records;
        });
    },
    load() {
      api.getCurrUserItem().then((res) => {
        this.idis = res.data.rname;
      });
      // 考核记录管理 评分类别 列表
      api.getScoreCategoryList({
        page: this.page,
        pageSize: this.pageSize,
        starts: 0,
      })
        .then((res) => {
          console.log(res, "获得的评分类别");
          this.options = res.data.records;
          console.log(res);
        });
      this.changeSelect();
    },
    cancel() {
      this.$emit("changeDialogFormVisible", false);
    },
    getTrue() {
      console.log("true");
      return true;
    },
    getFalse() {
      return false;
    },
    createData(form) {
      console.log(this.form, "--0");
      if (this.form.minscore > this.form.maxscore) {
        // 弹出消息
        this.$message.warning("下限不能大于上限");
        return;
      }
      if (this.form.score < this.form.minscore) {
        // 弹出消息
        this.$message.warning("初始分不能小于下限");
        return;
      }
      if (this.form.score > this.form.maxscore) {
        // 弹出消息
        this.$message.warning("初始分不能大于上限");
        return;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log("hahaha");
          let a = this.form;
          a.starts = 0;
          console.log(a);
          api.addStuScoringDetails(a).then((res) => {
            console.log(res);
            this.$emit("changeDialogFormVisible", false);
          });
        }
      });
    },
    updateData() {
      console.log(this.form);
      console.log("修改", this.dialogStatus);
      let a = this.form;
      api.updateStudentIndex(a).then((res) => {
        console.log(res);
        this.$emit("changeDialogFormVisible", false);
      });
    },
    addNew(temp) {
      console.log(temp);
      this.form = temp;
    },
    showData(record) {
      console.log(record, "00");
      this.form = record;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form .el-form--label-center .el-form--inline {
  padding: 20px;

  .el-form-item__content {
    display: flex;

    .el-date-editor {
      .el-input__inner {
        width: 20%;
      }
    }
  }
}

// /deep/.el-dialog__title {
//   display: flex;
//   flex-direction: row;
// }
/deep/.el-form-item {
  width: 56%;
  min-width: 400px;
}

/deep/.el-form-item label {
  text-align: right;
}

.item2 {
  width: 105%;
}

.item {
  width: 108.5%;
}
</style>
